export const getAppUrl = () => {
  if (!process.env.APP_URL) {
    throw new Error('Invalid configuration: App url is missing.');
  }

  return process.env.APP_URL;
};

export const LOCAL_STORAGE_SEARCH_KEY = 'sdh-search-string';

export const LOCAL_STORAGE_CONSENT_KEY = 'sdh-consent';
